import { Suspense, lazy, useState } from "react";
import { Stack, ThemeProvider } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getCookie, setCookie } from "./helpers/cookieHelper";

import "./App.css";

import TopNavbar from "./components/TopNavbar/topNavbar";
import moment from "moment";

// const CardanoDashboard = lazy(() => import("./pages/CardanoDashboard/cardanoDashboard"));
const CardanoEpochs = lazy(() => import("./pages/CardanoEpochs/cardanoEpochs"));
const CardanoAssets = lazy(() => import("./pages/CardanoAssets/cardanoAssets"));
const NoPageFound = lazy(() => import("./pages/NoPageFound/noPageFound"));
const Home = lazy(() => import("./pages/Home/home"));

export enum DISPLAY_MODE {
  DARK = 'dark',
  LIGHT = 'light',
}

const App = () => {
  const [isLightMode, setIsLightMode] = useState(getCookie('display_mode') === DISPLAY_MODE.LIGHT);
  const bg = isLightMode? DISPLAY_MODE.LIGHT : DISPLAY_MODE.DARK;

  if(process.env.NODE_ENV !== 'development') {
    gtag('js', new Date());
    gtag('config', 'G-H38PNL0709');
  }

  const onToggleMode = () => {
    setIsLightMode(!isLightMode);
    setCookie('display_mode', !isLightMode? DISPLAY_MODE.LIGHT : DISPLAY_MODE.DARK, moment().add(100, 'year').valueOf());
  };
  
  return (
    <ThemeProvider>
      <Stack id="vstack-window" className={bg}>
        <Router>
          <TopNavbar
            isLightMode={isLightMode}
            onToggleMode={onToggleMode}
          />
          <Suspense fallback={<div className="loading-spinner"><div className="nb-spinner"></div> <div className="loading-text">Loading...</div></div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/" element={<CardanoDashboard isLightMode={isLightMode} />} /> */}
              <Route path="epochs" element={<CardanoEpochs isLightMode={isLightMode} />} />
              <Route path="assets" element={<CardanoAssets isLightMode={isLightMode} />} />
              <Route path="*" element={<NoPageFound isLightMode={isLightMode} />}/>
            </Routes>
          </Suspense>
        </Router>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
