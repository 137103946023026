import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/Navbar";
// import { NavLink } from "react-router-dom";
// import Form from 'react-bootstrap/Form';
import { DISPLAY_MODE } from "../../App";

import './topNavbar.css';

interface IProps {
    isLightMode:boolean, 
    onToggleMode: () => void
}

const TopNavbar = ({ isLightMode, onToggleMode }: IProps) => {
    const variant = isLightMode? DISPLAY_MODE.LIGHT : DISPLAY_MODE.DARK;
    const bg = isLightMode? DISPLAY_MODE.LIGHT : DISPLAY_MODE.DARK;
    const iconClass = `${isLightMode? 'fas fa-sun ' : 'far fa-moon'} text-primary-link`;

    return (
        <Navbar bg={bg} variant={variant} className="p-0 border-bottom" sticky="top" expand="sm">
            <Container fluid>
                <Navbar.Brand href="/" className="p-0">
                    <img
                        src="/img/logo/logo.png"
                        height="60"
                        className="d-inline-block align-top"
                        alt="Kryptonware logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="me-auto mt-2">
                        {/* <NavLink to="/" className={({isActive}) => 'nav-link text-primary-link fw-bold ' + (isActive ? 'active' : 'not-active')}>Dashboard</NavLink>
                        <NavLink to="/epochs" className={({isActive}) => 'nav-link text-primary-link fw-bold ' + (isActive ? 'active' : 'not-active')}>Epochs</NavLink>
                        <NavLink to="/assets" className={({isActive}) => 'nav-link text-primary-link fw-bold ' + (isActive ? 'active' : 'not-active')}>Assets</NavLink> */}
                    </Nav>
                    <Nav className="mt-2">
                        <Navbar.Text>
                            <button
                                className="mode-button"
                                onClick={onToggleMode}
                            >
                                <i className={iconClass} />
                            </button>
                        </Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavbar;